<script lang="ts">
  import moment from 'moment';
  import { SessionStatus, type ISession } from '@/interface/ISession';
  import { UserRole } from '@/interface/IUser';
  import { openModal } from '@/lib/components/modal/modal.store';
  import { ToastNotification } from '@/lib/services/toast';
  import { t } from '@/locales/i18n';
  import { userStore } from '@/store/userStore';

  import Button from '../button/Button.svelte';
  import SessionUsersModal from '../modal/modals/SessionUsersModal.svelte';

  export let sessions: ISession[] = [];
  export let actions: {
    name: string;
    onAction: (session: ISession) => void;
  }[] = [];

  const handleCopyFeedbackLink = async (session: ISession) => {
    await navigator.clipboard.writeText(
      import.meta.env.VITE_BASE_URL + `/session/${session.id}/feedback`,
    );
    ToastNotification.success($t('dashboard.sessions.feedback-copy-success'));
  };

  const handleOpenFeedbackPage = (session: ISession) => {
    window.open(
      import.meta.env.VITE_BASE_URL + `/session/${session.id}/feedback`,
      '_blank',
    );
  };

  const handleShowSessionUsers = async (session: ISession) => {
    openModal(SessionUsersModal, { props: { session } });
  };

  const handleOpenBoard = (session: ISession) => {
    window.open(session.boardUrl, '_blank');
  };

  const isFinishedAndHasUnratedUsers = (session: ISession) => {
    return (
      session.status === SessionStatus.FINISHED &&
      $userStore?.role !== UserRole.USER &&
      session.users.some(
        (sessionUser) =>
          sessionUser.feedbackRating === 0 ||
          sessionUser.feedbackRating === null,
      )
    );
  };

  const isUserAndHasUnratedFeedback = (session: ISession) => {
    return (
      session.status === SessionStatus.FINISHED &&
      $userStore?.role === UserRole.USER &&
      session.users.some(
        (sessionUser) =>
          sessionUser.user.id === $userStore?.id && !sessionUser.feedbackRating,
      )
    );
  };
</script>

<table class="w-full text-left text-sm text-dark-grey">
  <thead>
    <tr class="h-10">
      <th scope="col"><span>{$t('dashboard.sessions.table-name')}</span></th>
      <th scope="col"><span>{$t('dashboard.sessions.table-host')}</span></th>
      <th scope="col"
        ><span>{$t('dashboard.sessions.table-experience')}</span></th
      >
      <th scope="col"
        ><span>{$t('dashboard.sessions.table-participants')}</span></th
      >
      <th scope="col"><span>{$t('dashboard.sessions.table-date')}</span></th>
      <th scope="col"><span>{$t('dashboard.sessions.table-status')}</span></th>
      {#if actions.length > 0}
        <th scope="col"
          ><span>{$t('dashboard.sessions.table-actions')}</span></th
        >
      {/if}
    </tr>
  </thead>
  <tbody>
    {#each sessions as session}
      <tr class="h-16">
        <td><span>{session.name}</span></td>
        <td><span>{session.host.name} {session.host.lastName}</span></td>
        <td><span>{session.experience.name}</span></td>
        <td>
          <Button
            testId={`participants-button-${session.id}`}
            on:click={() => handleShowSessionUsers(session)}
          >
            <span>{session.users.length}</span>
          </Button>
        </td>
        <td><span>{moment(session.scheduledDate).format('DD MMM YY')}</span></td
        >
        <td
          ><span
            >{session.status === SessionStatus.FINISHED
              ? $t('session-status-finished')
              : $t('session-status-pending')}</span
          ></td
        >
        <td>
          <div class="flex h-16 flex-row items-center justify-start gap-2">
            {#if isFinishedAndHasUnratedUsers(session)}
              <Button
                testId={`copy-feedback-link-button-${session.id}`}
                on:click={() => handleCopyFeedbackLink(session)}
              >
                <span
                  >{$t('dashboard.sessions.table-action-copy-feedback')}</span
                >
              </Button>
            {/if}
            {#if isUserAndHasUnratedFeedback(session)}
              <Button
                testId={`feedback-button-${session.id}`}
                on:click={() => handleOpenFeedbackPage(session)}
              >
                <span
                  >{$t('dashboard.sessions.table-action-send-feedback')}</span
                >
              </Button>
            {/if}
            {#if session.status !== SessionStatus.FINISHED}
              {#each actions as { name, onAction }}
                <Button
                  testId={`${name}-button-${session.id}`}
                  on:click={() => onAction(session)}
                >
                  <span
                    >{name === 'Finish session'
                      ? $t('dashboard.sessions.table-action-finish')
                      : name}</span
                  >
                </Button>
              {/each}
            {/if}
            <Button
              testId={`board-button-${session.id}`}
              on:click={() => handleOpenBoard(session)}
            >
              <span>{$t('dashboard.sessions.table-action-board')}</span>
            </Button>
          </div>
        </td>
      </tr>
    {/each}
  </tbody>
</table>
