<script lang="ts">
  import { type ITeam } from '@/interface/ITeam';
  import { type IUser } from '@/interface/IUser';
  import { ToastNotification } from '@/lib/services/toast';
  import {
    addTeamMember,
    getTenantUsers,
  } from '@/lib/services/together/together.api';
  import { t } from '@/locales/i18n';

  import Button from '../../button/Button.svelte';
  import InviteUserList from '../../lists/InviteUserList.svelte';
  import Loading from '../../loading/Loading.svelte';
  import SearchInput from '../../search-input/SearchInput.svelte';
  import { closeModal } from '../modal.store';

  export let team: ITeam;
  export let onSubmit: (() => void) | undefined = undefined;
  let tenantUsers: IUser[] = [];
  let filteredUsers: IUser[] = [];
  let selectedUserIds = new Set<IUser['id']>();

  const getUsers = async () => {
    try {
      const { users } = await getTenantUsers();
      tenantUsers = users;

      filteredUsers = tenantUsers;

      return filteredUsers;
    } catch (error) {
      ToastNotification.error('Could not fetch users');
      return [];
    }
  };

  const handleSelect = (user: IUser) => {
    selectedUserIds.has(user.id)
      ? selectedUserIds.delete(user.id)
      : selectedUserIds.add(user.id);
    selectedUserIds = new Set(selectedUserIds);
  };

  const handleSearch = (event: CustomEvent<any>) => {
    const { searchBy, searchQuery } = event.detail;
    filteredUsers = tenantUsers.filter((user) =>
      searchBy === 'name'
        ? `${user.name} ${user.lastName}`
            .toLowerCase()
            .includes(searchQuery.toLowerCase())
        : user.email.toLowerCase().includes(searchQuery.toLowerCase()),
    );
  };
  let isSubmitting = false;
  export const handleAddMember = async () => {
    isSubmitting = true;
    try {
      await Promise.all(
        Array.from(selectedUserIds).map((userId) =>
          addTeamMember(team.id, userId),
        ),
      );
      ToastNotification.success(
        $t('dashboard.teams.view.add-member-modal-success'),
      );
      onSubmit?.();
      closeModal();
    } catch (error) {
      console.error(error);
      ToastNotification.error(
        $t('dashboard.teams.view.add-member-modal-error'),
      );
    } finally {
      isSubmitting = false;
    }
  };

  const filterOutMembers = (users: IUser[], teamUsers: IUser[]) =>
    users.filter(
      (user) => !teamUsers.some((teamUser) => teamUser.id === user.id),
    );

  $: filteredUsers = filterOutMembers(filteredUsers, team.users);
</script>

<div class="flex flex-col gap-4 rounded-lg bg-white p-6">
  <div class="w-full text-center">
    <h2 class="text-2xl font-bold text-dark-grey">
      {$t('dashboard.teams.view.add-member-modal-title')}
    </h2>
  </div>
  {#await getUsers()}
    <Loading loadingSize="h-20 w-20" />
  {:then users}
    {#if users}
      <SearchInput
        searchByOptions={['name', 'email']}
        on:search={handleSearch}
      />
      <InviteUserList
        users={filteredUsers}
        selectedUserIds={selectedUserIds}
        onSelect={handleSelect}
      />
    {:else}
      <p>Could not fetch users</p>
    {/if}
  {/await}
  <div class="flex w-full flex-row justify-between">
    <Button on:click={() => closeModal()}
      >{$t('dashboard.teams.view.add-member-modal-cancel-button')}</Button
    >
    <Button
      testId="add-member-confirm-button"
      disabled={selectedUserIds.size === 0 || isSubmitting}
      on:click={handleAddMember}
      >{$t('dashboard.teams.view.add-member-modal-add-button')}</Button
    >
  </div>
</div>
