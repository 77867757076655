<script lang="ts">
  import LandingPageInfoElement from '@/assets/svg/LandingPageInfoElement.svelte';
  import Rizz2 from '@/assets/svg/Rizz2.svelte';
  import TogetherLogo from '@/assets/svg/TogetherLogo.svelte';
  import SignupMultiStepForm from '@/routes/signup/SignupMultiStepForm.svelte';
</script>

<main class="flex h-screen w-full flex-row items-center justify-center">
  <section
    class="hidden h-full w-[90rem] flex-col items-center justify-center overflow-hidden bg-[#E0E3FF] pt-6 lg:flex"
  >
    <div class="flex h-60 w-full flex-col items-center justify-center gap-6">
      <TogetherLogo className="h-24" />
      <h1 class="text-3xl font-semibold text-dark-grey">
        Bring remote teams closer
      </h1>
    </div>

    <LandingPageInfoElement styles="h-[50rem] " />
  </section>
  <section
    class="relative flex h-full w-full flex-col items-center justify-center lg:overflow-hidden"
  >
    <Rizz2 styles="absolute w-[88rem] -right-20" />
    <SignupMultiStepForm />
  </section>
</main>
