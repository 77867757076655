<script lang="ts">
  import LocaleSelector from '@/lib/components/locale-selector/LocaleSelector.svelte';
  import Page from '@/lib/components/page/Page.svelte';
  import { t } from '@/locales/i18n';
</script>

<Page>
  <div
    class="h-max-fit flex w-full flex-col gap-4 rounded-none border-x border-t border-light-grey bg-white p-8"
  >
    <h1 class="mt-1 text-3xl font-bold">
      {$t('dashboard.settings.general.title')}
    </h1>
    <p>{$t('dashboard.settings.general.description')}</p>
    <LocaleSelector navbarPlacement={false} />
  </div>
</Page>
