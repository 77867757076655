interface Translations {
  [locale: string]: {
    [key: string]: string;
  };
}

const translations: Translations = {
  en: {
    // Navbar
    'navbar.element-home': 'Home',
    'navbar.element-together': 'What is Together?',
    'navbar.element-catalogue': 'Catalogue',
    'navbar.element-aboutus': 'About Us',
    'navbar.element-integrations': 'Integrations',
    'navbar.element-dashboard': 'Dashboard',
    'navbar.element-logout': 'Logout',
    'navbar.element-login': 'Login',
    'navbar.element-signup': 'Sign Up',

    // Home
    'home.title': 'Bring remote<br />teams closer',
    'home.subtitle': 'Connect with your team from anywhere in the world.',
    'home.cta-button': 'Try Together!',
    'home.info-title': 'What is Together?',
    'home.info-text-1': `Together is a platform that offers unique experiences that strengthen teamwork and foster meaningful relationships between members, regardless of physical distance. 
                        <br /><br />
                        Integrated with platforms like Miro and Slack, which teams use regularly, allows them to quickly dive into the activities and connect. 
                        <br /><br />
                        With our platform, you will also cultivate a work environment where each member feels connected and engaged by reducing feelings of isolation from remote work.
                        <br />`,
    'home.info-subtitle': 'Connect your Team Today!',
    'home.info-text-2': `Don't let physical distance affect your team connection.<br /> Discover how Together can take your remote collaboration to the next level. 
                        <br /><br />
                        Sign up for a free demo and start building a stronger, more connected and productive team.`,
    'home.catalogue-title': 'Our Experiences',
    'home.aboutus-title': 'About Us',
    'home.aboutus-text-1':
      "Together was born in response to Bigger Tech's need to bring closer its more than 100 employees working from all over the world.",
    'home.aboutus-text-2':
      'The experiences offered by Together are designed based on Agile and coaching principles to foster conversations, fun, and moments of reflection between team members.',
    'home.aboutus-text-3':
      'From the experience of using Together in Bigger Tech teams, we highlight the following benefits:',
    'home.aboutus-list-1':
      'Greater connection between team members<br /><br />',
    'home.aboutus-list-2':
      'A more positive and motivating work environment<br /><br />',
    'home.aboutus-list-3': 'Increased sense of belonging despite the distance',

    // Footer
    'footer.copyright': '© 2024 Together - All Rights Reserved',
    'footer.home': 'Home',
    'footer.login': 'Login',
    'footer.signup': 'Sign Up',
    'footer.terms': 'Terms',
    'footer.privacy': 'Privacy',
    'footer.support': 'Support',

    // Integrations
    'integrations.infotag': 'Together Integration with Slack',
    'integrations.title':
      'The Together integration with Slack allows you to automatically send invitations to experiences, reminders, and creates a space to strengthen team relationships and have fun.',
    'integrations.button': 'Know more!',
    'integrations.claim-text-1': `The Together integration with Slack allows you to easily onboard users, automatically send reminders, and create a dedicated channel for each experience.`,
    'integrations.claim-text-2': `After registering on our platform, you will be able to install the Together App in your Slack workspace and invite your team.`,
    'integrations.claim-text-3': `<strong>Important:</strong> The person registering must have admin permissions in the company’s Slack workspace.`,
    'integrations.claim-text-4': `For any questions, please contact us at <a href="emailto:together@togetherexperiences.com" class="text-blue-600 hover:text-blue-800">together@togetherexperiences.com</a>`,
    'integrations.info-title-1':
      'Connect with your Slack workspace and invite your team members quickly',
    'integrations.info-title-2':
      'Start Sessions for you team and automatically create conversation channels to get the most of the Experience',
    'integrations.info-text-1':
      'If your team is already organized and connected with Slack, you do not need to worry about asking them to join the Together platform by themselves. Our platform is designed to be easily integrated so you can connect to your workspace and invite your team members to join your organization. The integration is done in just a few clicks and will create accounts for every member of your team that you want to invite to the platform.',
    'integrations.info-text-2':
      'Together integrations with Slack is meant to make it easy for you and for you team to be ready to use. Enjoy the convenience of having Slack channels created to share and communicate with your team when a Session is scheduled. Also, your team can start sharing their opinions and expectations. We will send you notifications when needed and provide you with all that your teams need for get the most of the Experience. From reminders to updates, we will be there to help you every step of the way. You only will need to do one thing: enjoy the Experience.',

    // Support
    'support.title': 'Support',
    'support.text-1': `If you need help, have any questions, or just want to say hello, please don't hesitate to contact our dedicated support team at <a class="text-blue-600 hover:text-blue-800" href="mailto:together@togetherexperiences.com">together@togetherexperiences.com</a>. We're here to help and want to ensure you have an amazing experience with our app. We'll do our best to respond promptly and help resolve any issues you may be facing.`,
    'support.text-2': `Please read our <a href="/privacy" class="text-blue-600 hover:text-blue-800">privacy policy</a> and our <a href="/terms" class="text-blue-600 hover:text-blue-800">terms of service</a> to learn more. You can execute your rights at any moment by contacting us.`,

    // Signup
    'signup.step-1': 'Info',
    'signup.step-2': 'Sign Up',
    'signup.button-back': 'Back',
    'signup.button-next': 'Next',
    'signup.info-text-1': `Thanks for signing up for the trial of our platform! We’re excited to have you with us.<br /><br />
                          After your trial period is approved, you’ll receive your access credentials via email.`,
    'signup.info-text-2': `If you have any questions, please contact us at together@togetherexperiences.com.<br /><br />
                          Enjoy your trial!`,
    'signup.form-title': 'Sign Up',
    'signup.form-subtitle': `Complete the form below and let's get Together`,
    'signup.form-confirm': 'Try Together!',
    'signup.form-success':
      'Thank you for your submission. We will send your credentials once the submission is approved.',
    'signup.form-error': 'We couldn’t submit your request.',
    'signup.form-orgname-input-label': 'Your organization name',
    'signup.form-orgname-input-placeholder': 'Organization name',
    'signup.form-orgname-required': 'Organization name is required',
    'signup.form-name-required': 'Name is required',
    'signup.form-name-input-label': 'Name',
    'signup.form-name-input-placeholder': 'Name',
    'signup.form-lastname-required': 'Last name is required',
    'signup.form-lastname-input-label': 'Last name',
    'signup.form-lastname-input-placeholder': 'Last name',
    'signup.form-email-input-label': 'Email',
    'signup.form-email-input-placeholder': 'Email',
    'signup.form-email-required': 'Email is required',
    'signup.form-email-invalid': 'Email is not valid',
    'signup.form-trial-session-participants-mails-invalid':
      'Emails are not valid or are duplicated',
    'signup.form-trial-session-schedule-date-input-label': 'Date',
    'signup.form-trial-session-schedule-date-input-placeholder': 'Date',
    'signup.form-trial-session-participants-mails-input-label': 'Emails',
    'signup.form-trial-session-participants-mails-input-placeholder': 'Emails',
    'signup.form-accept-terms':
      "I agree to the <a href='/terms' class='text-blue-600 hover:text-blue-800'>Terms and Conditions</a> and <a href='/privacy' class='text-blue-600 hover:text-blue-800'>Privacy Policy</a>",
    'signup.form-terms-required': 'You must accept the terms and conditions',
    'signup.home-button': 'Go Home',

    // Login
    'login.title': 'Login',
    'login.home-button': 'Go Home',
    'login.form-title': 'Login',
    'login.form-subtitle': 'Connect with your team from anywhere in the world',
    'login.form-forgot-password': 'Forgot password?',
    'login.form-submit-button': 'Login',
    'login.form-no-account': "Don't have an account? ",
    'login.form-signup-link': 'Sign Up',
    'login.form-email-invalid': 'Email is not valid',
    'login.form-email-required': 'Email is required',
    'login.form-email-input-placeholder': 'Email',
    'login.form-email-input-label': 'Email',
    'login.form-password-required': 'Password is required',
    'login.form-password-input-label': 'Password',
    'login.form-password-input-placeholder': 'Password',
    'login.form-new-password-required': 'You need to create a new password',
    'login.form-success': 'Login successful!',
    'login.form-error': 'Failed to login, please try again',

    // Login - New Password
    'login.new-password-form-title':
      'You have to set a new password for your account',
    'login.new-password-form-label': 'New password',
    'login.new-password-form-placeholder': 'Enter your new password',
    'login.new-password-form-repeat-placeholder': 'Repeat your new password',
    'login.new-password-form-submit-button': 'Confirm password',
    'login.new-password-form-required': 'Password is required',
    'login.new-password-form-min': 'Password must be at least 8 characters',
    'login.new-password-form-invalid':
      'Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 number, 1 special character',
    'login.new-password-form-mismatch': 'Passwords must match',
    'login.new-password-form-success': 'Password updated successfully',
    'login.new-password-form-error':
      'Error updating password, please try again',

    // Dashboard - Sidebar
    'dashboard.sidebar.welcome': 'Welcome',
    'dashboard.sidebar.tenant-selector': 'Current tenant:',
    'dashboard.sidebar.users': 'Users',
    'dashboard.sidebar.teams': 'Teams',
    'dashboard.sidebar.experiences': 'Experiences',
    'dashboard.sidebar.sessions': 'Sessions',
    'dashboard.sidebar.settings': 'Settings',
    'dashboard.sidebar.logout': 'Logout',

    // Dashboard - Users
    'dashboard.users.title': 'Users',
    'dashboard.users.subtitle': 'Your team',
    'dashboard.users.button': 'Add user',
    'dashboard.users.table-name': 'Name',
    'dashboard.users.table-email': 'Email',
    'dashboard.users.table-role': 'Role',
    'dashboard.users.table-actions': 'Actions',
    'dashboard.users.table-actions-assign-role': 'Assign role',
    'dashboard.users.table-actions-resend-credentials': 'Resend credentials',
    'dashboard.users.table-actions-delete': 'Delete',
    'dashboard.users.table-no-users': 'No users found',
    'dashboard.users.assign-role-title': `Update {{name}}'s role`,
    'dashboard.users.assign-role-confirm': 'Confirm',
    'dashboard.users.assign-role-close': 'Close',
    'dashboard.users.assign-role-success': 'User role updated successfully!',
    'dashboard.users.assign-role-error': 'Error updating user role',
    'dashboard.users.invite-users-title': 'Invite Users',
    'dashboard.users.invite-users.description':
      'Select the users from your Slack workspace that you want to invite',
    'dashboard.users.invite-users.not-found': 'No workspace users found',
    'dashboard.users.invite-users.integration-required': `⚠ To invite users you need to install and allow permissions from Together App in your Slack workspace.<br /> ✋ Remember that you must be a <b> workspace administrator</b> in your company's Slack to proceed with the installation. <br /> 🚧 The Together app is in process of being approved by Slack team.`,
    'dashboard.users.invite-users.get-success':
      'Slack users fetched successfully!',
    'dashboard.users.invite-users.get-error': 'Error connecting with Slack',
    'dashboard.users.invite-users.invite-success': 'User invited successfully!',
    'dashboard.users.invite-users.invite-error': 'Error inviting user',
    'dashboard.users.invite-users.email-invitation-description':
      'Or you can invite them by email',
    'dashboard.users.invite-users.email-invitation-btn': 'Invite',
    'dashboard.users.invite-users.table-name': 'Full Name',
    'dashboard.users.invite-users.table-email': 'Email',
    'dashboard.users.invite-users.table-options': 'Options',
    'dashboard.users.invite-users.name-placeholder': 'Name',
    'dashboard.users.invite-users.last-name-placeholder': 'Last Name',
    'dashboard.users.invite-users.email-placeholder': 'Email',
    'dashboard.users.invite-users.table-no-users':
      'There are no users from your workspace available to invite',
    'dashboard.users.resend-credentials-success':
      'Credentials sent successfully!',
    'dashboard.users.resend-credentials-error': 'Error sending credentials',
    'dashboard.users.resend-credentials-modal-text': 'Resend credentials to ',
    'dashboard.users.delete-user-success': 'User deleted successfully!',
    'dashboard.users.delete-user-error': 'Error deleting user',
    'dashboard.users.delete-user-modal-title': 'Confirm',
    'dashboard.users.delete-user-modal-text':
      'Are you sure you want to delete the user with email ',

    // Dashboard - Teams
    'dashboard.teams.title': 'Teams',
    'dashboard.teams.table-name': 'Name',
    'dashboard.teams.table-host': 'Host',
    'dashboard.teams.table-members': 'Members',
    'dashboard.teams.table-actions': 'Actions',
    'dashboard.teams.add-team-button': 'Add team',
    'dashboard.teams.table-actions-manage': 'Manage',
    'dashboard.teams.table-actions-delete': 'Delete',
    'dashboard.teams.delete-team-modal-title': 'Delete team',
    'dashboard.teams.delete-team-modal-text':
      'Are you sure you want to delete team',
    'dashboard.teams.create-team-modal.title': 'Create team',
    'dashboard.teams.create-team-modal.name-label': 'Name',
    'dashboard.teams.create-team-modal.language-label': 'Language',
    'dashboard.teams.create-team-modal.name-error': 'Name is required',
    'dashboard.teams.create-team-modal.name-placeholder': 'Team name',
    'dashboard.teams.create-team-modal.language-error': 'Language is required',
    'dashboard.teams.create-team-modal.language-english-option': 'English',
    'dashboard.teams.create-team-modal.language-spanish-option': 'Spanish',
    'dashboard.teams.create-team-modal.success': 'Team created successfully!',
    'dashboard.teams.create-team-modal.error': 'Error creating team',
    'dashboard.teams.create-team-modal.submit-button': 'Confirm',
    'dashboard.teams.create-team-modal.cancel-button': 'Cancel',
    'dashboard.teams.edit-team-modal.title': 'Edit team',
    'dashboard.teams.view.team-title': 'Team',
    'dashboard.teams.view.team-sessions-title': 'Team sessions',
    'dashboard.teams.view.add-member-button': 'Add member',
    'dashboard.teams.view.edit-team-button': 'Edit',
    'dashboard.teams.view.language-label': 'Language',
    'dashboard.teams.view.edit-team-modal-title': 'Edit team',
    'dashboard.teams.view.edit-team-modal.success':
      'Team updated successfully!',
    'dashboard.teams.view.edit-team-modal.error': 'Error updating team',
    'dashboard.teams.view.add-member-modal-title': 'Select new team members',
    'dashboard.teams.view.add-member-modal-cancel-button': 'Cancel',
    'dashboard.teams.view.add-member-modal-add-button': 'Add',
    'dashboard.teams.view.add-member-modal-error': 'Error adding member',
    'dashboard.teams.view.add-member-modal-success':
      'Member added successfully!',
    'dashboard.teams.view.member.table-no-members': 'No members found',
    'dashboard.teams.view.table-action-remove': 'Remove',
    'dashboard.teams.view.remove-member-modal-text':
      'Are you sure you want to remove the member',
    'dashboard.teams.view.remove-member-modal-title': 'Remove member',
    'dashboard.teams.view.remove-member-modal-success':
      'Member removed successfully!',
    'dashboard.teams.view.remove-member-modal-error': 'Error removing member',
    'dashboard.teams.view.change-host-modal-success':
      'Host changed successfully!',
    'dashboard.teams.view.change-host-modal-error': 'Error changing host',
    'dashboard.teams.view.change-host-modal-title': 'Select new host',
    'dashboard.teams.view.change-host-modal-cancel-button': 'Cancel',
    'dashboard.teams.view.change-host-modal-change-button': 'Confirm',

    // Dashboard - Experiences
    'dashboard.experiences.title': 'Experiences',
    'dashboard.experiences.fetch-error': 'Something went wrong: {{error}}',
    'dashboard.experiences.details.min-participants': 'Min 2 participants',
    'dashboard.experiences.details.res-download':
      'Download resource for this experience',
    'dashboard.experiences.details.instructions-title':
      'Instructions for the team',
    'dashboard.experiences.start-session-title': 'Who do you want to invite?',
    'dashboard.experiences.start-session-subtitle':
      'Select the users you want to invite',
    'dashboard.experiences.start-session-no-users':
      'No users found. Trying inviting them from your Slack workspace!',
    'dashboard.experiences.start-session-fetch-users-error':
      'Error at trying to get users.',
    'dashboard.experiences.start-session-success':
      'Session created successfully!',
    'dashboard.experiences.start-session-error': 'Error creating session',
    'dashboard.experiences.start-session-name-input-label': 'Session name',
    'dashboard.experiences.start-session-name-input-placeholder':
      'Session name',
    'dashboard.experiences.start-session-name-input-error':
      'Session name can only contain letters and numbers',
    'dashboard.experiences.start-session-date-input-label': 'Date',
    'dashboard.experiences.start-session-confirm-button': 'Confirm',
    'dashboard.experiences.start-session-section-title': 'Start session',
    'dashboard.experiences.start-session-section-subtitle':
      'You can schedule this experience by adding your team members or inviting new members',
    'dashboard.experiences.start-session-section-button': 'Start experience',
    'dashboard.experiences.start-session-select-team-label': 'Select team',
    'dashboard.experiences.start-session-select-team-option-label': 'No team',
    'dashboard.experiences.feedback-title': 'Users review',
    'dashboard.experiences.no-feedback': 'No feedback yet',
    'dashboard.experiences.language-label': 'Experiences language: ',
    'dashboard.experiences.category': 'Category: ',
    'dashboard.experiences.no-experiences':
      'There are no experiences available',

    // Dashboard - Sessions
    'dashboard.sessions.title': 'Sessions',
    'dashboard.sessions.no-sessions': 'No sessions yet',
    'dashboard.sessions.finish-success': 'Session finished successfully!',
    'dashboard.sessions.finish-error': 'Failed to finish session',
    'dashboard.sessions.finish-title': 'Finish session',
    'dashboard.sessions.feedback-copy-success':
      'Feedback link copied to clipboard',
    'dashboard.sessions.table-name': 'Name',
    'dashboard.sessions.table-host': 'Host',
    'dashboard.sessions.table-experience': 'Experience',
    'dashboard.sessions.table-participants': 'Participants',
    'dashboard.sessions.table-date': 'Date',
    'dashboard.sessions.table-status': 'Status',
    'dashboard.sessions.table-actions': 'Actions',
    'dashboard.sessions.table-action-copy-feedback': 'Copy feedback link',
    'dashboard.sessions.table-action-send-feedback': 'Send feedback',
    'dashboard.sessions.table-action-finish': 'Finish session',
    'dashboard.sessions.table-action-board': 'Board',
    'dashboard.sessions.card-rating': 'Rating',
    'dashboard.sessions.card-comment': 'Comment',
    'dashboard.sessions.card-feedback-pending': 'Feedback pending',
    'dashboard.sessions.feedback-form-title':
      'Leave your feedback for the last session',
    'dashboard.sessions.feedback-form-rating': 'Rating:',
    'dashboard.sessions.feedback-form-comment-label': 'Comment',
    'dashboard.sessions.feedback-form-comment-placeholder': 'Optional',
    'dashboard.sessions.feedback-form-submit': 'Submit',
    'dashboard.sessions.feedback-form-success': 'Feedback submitted',
    'dashboard.sessions.feedback-form-error': 'Error submitting feedback',

    // Dashboard - Settings
    'dashboard.settings.title': 'Settings',
    'dashboard.settings.tabs.general': 'General',
    'dashboard.settings.general.title': 'General',
    'dashboard.settings.general.description':
      'Configure your general settings.',
    'dashboard.settings.tabs.billing': 'Billing',
    'dashboard.settings.billing.title': 'Billing',
    'dashboard.settings.billing.description':
      'Select the plan that best fits your needs.',
    'dashboard.settings.billing.free-plan-card-title': 'Free',
    'dashboard.settings.billing.free-plan-card-price-label': '/ month',
    'dashboard.settings.billing.free-plan-card-description-1':
      'Limited Experiences',
    'dashboard.settings.billing.free-plan-card-btn-current': 'Current plan',
    'dashboard.settings.billing.free-plan-card-btn-update': 'Set to Free',
    'dashboard.settings.billing.pro-plan-card-title': 'Pro',
    'dashboard.settings.billing.pro-plan-card-price-label': '/ month',
    'dashboard.settings.billing.pro-plan-card-description-1':
      'Unlimited Experiences',
    'dashboard.settings.billing.pro-plan-card-btn-current': 'Current plan',
    'dashboard.settings.billing.pro-plan-card-btn-update': 'Set to Pro',

    // Experience Card
    'experience.play-button': 'Play',
    'experience.view-board-button': 'View board',
    'experience.min-participants': 'Min 2 participants',

    // Forgot Password
    'forgot-password.title': 'Forgot password',
    'forgot-password.email-label': 'Email',
    'forgot-password.new-password-label': 'New password',
    'forgot-password.repeat-password-label': 'Repeat password',
    'forgot-password.code-label': 'Code',
    'forgot-password.btn-reset-password': 'Reset password',
    'forgot-password.btn-send-code': 'Send code',
    'forgot-password.reset-password-success': 'Password updated successfully',
    'forgot-password.reset-password-error':
      'Error recovering password, please try again',
    'forgot-password.send-code-success': 'Check your email for the code',
    'forgot-password.send-code-error': 'Error sending code, please try again',

    // Misc
    'paginator.next': 'Next',
    'paginator.previous': 'Prev',
    'search.by-name': 'Name',
    'search.by-email': 'Email',
    'search.placeholder': 'Search',
    'confirmation.modal.title': 'Confirm',
    'confirmation.modal.text': 'Are you sure?',
    'confirmation.modal.error': 'Error confirming action',
    'confirmation.modal.confirm': 'Confirm',
    'confirmation.modal.close': 'Close',
    'locale.select': 'Language:',
    'session-status-finished': 'Finished',
    'session-status-pending': 'Pending',
  },
  es: {
    // Navbar
    'navbar.element-home': 'Inicio',
    'navbar.element-together': '¿Que es Together?',
    'navbar.element-catalogue': 'Catálogo',
    'navbar.element-aboutus': 'Nosotros',
    'navbar.element-integrations': 'Integraciones',
    'navbar.element-dashboard': 'Dashboard',
    'navbar.element-logout': 'Cerrar sesión',
    'navbar.element-login': 'Iniciar sesión',
    'navbar.element-signup': 'Registrarse',

    // Home
    'home.title': 'Bring remote<br />teams closer',
    'home.subtitle': 'Connect with your team from anywhere in the world.',
    'home.cta-button': 'Prueba Together!',
    'home.info-title': '¿Que es Together?',
    'home.info-text-1': `Together es una plataforma que ofrece experiencias únicas que refuerzan el trabajo en equipo y fomentan relaciones significativas entre sus miembros, independientemente de la distancia física. 
      <br /><br />
      La integración con plataformas como Miro y Slack, que los equipos utilizan habitualmente, les permite sumergirse rápidamente en las actividades y conectarse. 
      <br /><br />
      Con nuestra plataforma, también cultivará un entorno de trabajo en el que cada miembro se sienta conectado y comprometido, reduciendo los sentimientos de aislamiento del trabajo remoto.
      <br />`,
    'home.info-subtitle': '¡Conecta tu equipo hoy!',
    'home.info-text-2': `No deje que la distancia física afecte a la conexión de su equipo.<br /> Descubra cómo Together puede llevar su colaboración remota al siguiente nivel. 
      <br /><br />
      Solicite una demostración gratuita y empiece a crear un equipo más fuerte, conectado y productivo.`,
    'home.catalogue-title': 'Nuestra Experiencias',
    'home.aboutus-title': 'Nosotros',
    'home.aboutus-text-1':
      'Together nace para responder a la necesidad de Bigger Tech para que sus 100 emplados se conecten en todo el mundo.',
    'home.aboutus-text-2':
      'Las experiencias que ofrece Together están diseñadas basándose en los principios de Agile y coaching para fomentar las conversaciones, la diversión y los momentos de reflexión entre los miembros del equipo.',
    'home.aboutus-text-3':
      'De la experiencia de usar Together en los equipos de Bigger Tech, resaltamos los siguientes beneficios: ',
    'home.aboutus-list-1':
      'Mayor conectividad entre los miembros de los equipos',
    'home.aboutus-list-2': 'Un ambiente de trabajo más positivo y motivante',
    'home.aboutus-list-3':
      'Mayor sensacion de pertenencia, sin importar la distancia',

    // Footer
    'footer.copyright': '© 2024 Together - Todos los derechos reservados',
    'footer.home': 'Inicio',
    'footer.login': 'Iniciar sesión',
    'footer.signup': 'Registrarse',
    'footer.terms': 'Términos',
    'footer.privacy': 'Privacidad',
    'footer.support': 'Soporte',

    // Integrations
    'integrations.infotag': 'Integración con Slack',
    'integrations.title':
      'La integración con Slack te permite enviar invitaciones automáticamente, recordatorios y crea un espacio para mejorar las relaciones de equipo y tener diversión.',
    'integrations.button': '¡Conoce más!',
    'integrations.claim-text-1': `La integración de Together con Slack te permite incorporar usuarios fácilmente, enviar recordatorios automáticamente y crear un canal dedicado para cada experiencia.`,
    'integrations.claim-text-2': `Después de registrarte en nuestra plataforma, podrás instalar la aplicación Together en tu espacio de trabajo de Slack e invitar a tu equipo.`,
    'integrations.claim-text-3': `<strong>Importante:</strong> La persona que se registre debe tener permisos de administrador en el espacio de trabajo de Slack de la empresa.`,
    'integrations.claim-text-4': `Si tienes alguna pregunta, escribinos a together@togetherexperiences.com`,

    // Support
    'support.title': 'Soporte',
    'support.text-1': `Si necesitas ayuda, tienes alguna pregunta o simplemente quieres saludar, no dudes en contactar a nuestro equipo de soporte dedicado en <a class="text-blue-600 hover:text-blue-800" href="mailto:together@togetherexperiences.com">together@togetherexperiences.com</a>. Estamos aquí para ayudarte y queremos asegurarnos de que tengas una experiencia increíble con nuestra aplicación. Haremos nuestro mejor esfuerzo para responder rápidamente y ayudar a resolver cualquier problema que puedas estar enfrentando.`,
    'support.text-2': `Por favor, lee nuestra <a href="/privacy" class="text-blue-600 hover:text-blue-800">política de privacidad</a> y nuestros <a href="/terms" class="text-blue-600 hover:text-blue-800">términos de servicio</a> para obtener más información. Puedes ejercer tus derechos en cualquier momento contactándonos.`,

    // Signup
    'signup.step-1': 'Información',
    'signup.step-2': 'Registrarse',
    'signup.button-back': 'Atras',
    'signup.button-next': 'Siguiente',
    'signup.info-text-1': `Gracias por registrarte para probar nuestra plataforma. Estamos encantados de tenerte con nosotros<br /><br /.
                          Una vez aprobado tu periodo de prueba, recibirás tus credenciales de acceso por correo electrónico.`,
    'signup.info-text-2': `Si tiene alguna pregunta, póngase en contacto con nosotros en together@togetherexperiences.com.<br /><br />
                          ¡Disfrute de su prueba!`,
    'signup.form-title': 'Registro',
    'signup.form-subtitle': 'Complete el siguiente formulario para registrarse',
    'signup.form-confirm': 'Confirmar',
    'signup.form-success':
      'Gracias por tu solicitud. Te enviaremos tus credenciales una vez que la solicitud sea aprobada.',
    'signup.form-error': 'No pudimos enviar tu solicitud.',
    'signup.form-orgname-input-label': 'El nombre de tu organización',
    'signup.form-orgname-input-placeholder': 'Nombre de la organización',
    'signup.form-orgname-required':
      'El nombre de la organización es obligatorio',
    'signup.form-name-required': 'El nombre es obligatorio',
    'signup.form-name-input-label': 'Nombre',
    'signup.form-name-input-placeholder': 'Nombre',
    'signup.form-lastname-required': 'El apellido es obligatorio',
    'signup.form-lastname-input-label': 'Apellido',
    'signup.form-lastname-input-placeholder': 'Apellido',
    'signup.form-email-input-label': 'Email',
    'signup.form-email-input-placeholder': 'Email',
    'signup.form-email-required': 'El email es obligatorio',
    'signup.form-email-invalid': 'El email no es válido',
    'signup.form-trial-session-participants-mails-invalid':
      'Los emails no son validos o son duplicados',
    'signup.form-trial-session-schedule-date-input-label': 'Fecha',
    'signup.form-trial-session-schedule-date-input-placeholder': 'Fecha',
    'signup.form-trial-session-participants-mails-input-label': 'Emails',
    'signup.form-trial-session-participants-mails-input-placeholder': 'Emails',
    'signup.form-accept-terms':
      'Acepto los <a href="/terms" class="text-blue-600 hover:text-blue-800">términos y condiciones</a> y la <a href="/privacy" class="text-blue-600 hover:text-blue-800">política de privacidad</a>',
    'signup.form-terms-required': 'Debe aceptar los términos y condiciones',
    'signup.home-button': 'Ir al inicio',

    // Login
    'login.title': 'Iniciar sesión',
    'login.home-button': 'Ir al inicio',
    'login.form-title': 'Iniciar sesión',
    'login.form-subtitle': 'Conecta con tu equipo en cualquier parte del mundo',
    'login.form-forgot-password': '¿Olvidaste tu contraseña?',
    'login.form-submit-button': 'Ingresar',
    'login.form-no-account': '¿No tienes una cuenta? ',
    'login.form-signup-link': 'Registrate',
    'login.form-email-invalid': 'El email no es válido',
    'login.form-email-required': 'El email es obligatorio',
    'login.form-email-input-placeholder': 'Email',
    'login.form-email-input-label': 'Email',
    'login.form-password-required': 'La contraseña es obligatoria',
    'login.form-password-input-label': 'Contraseña',
    'login.form-password-input-placeholder': 'Contraseña',
    'login.form-new-password-required': 'Necesita crear una nueva contraseña',
    'login.form-success': '¡Inicio de sesión exitoso!',
    'login.form-error':
      'No se pudo iniciar sesión, por favor inténtelo de nuevo',

    // Login - New Password
    'login.new-password-form-title':
      'Debe establecer una nueva contraseña para su cuenta',
    'login.new-password-form-label': 'Nueva contraseña',
    'login.new-password-form-placeholder': 'Ingrese su nueva contraseña',
    'login.new-password-form-repeat-placeholder': 'Repita su nueva contraseña',
    'login.new-password-form-submit-button': 'Confirmar contraseña',
    'login.new-password-form-required': 'La contraseña es obligatoria',
    'login.new-password-form-min':
      'La contraseña debe tener al menos 8 caracteres',
    'login.new-password-form-invalid':
      'La contraseña debe contener al menos 1 letra mayúscula, 1 letra minúscula, 1 número, 1 carácter especial',
    'login.new-password-form-mismatch': 'Las contraseñas deben coincidir',
    'login.new-password-form-success': 'Contraseña actualizada con éxito',
    'login.new-password-form-error':
      'Error al actualizar la contraseña, por favor inténtelo de nuevo',

    // Dashboard - Sidebar
    'dashboard.sidebar.welcome': 'Bienvenido',
    'dashboard.sidebar.tenant-selector': 'Organzacion actual:',
    'dashboard.sidebar.users': 'Usuarios',
    'dashboard.sidebar.teams': 'Equipos',
    'dashboard.sidebar.experiences': 'Experiencias',
    'dashboard.sidebar.sessions': 'Sesiones',
    'dashboard.sidebar.settings': 'Ajustes',
    'dashboard.sidebar.logout': 'Salir',

    // Dashboard - Users
    'dashboard.users.title': 'Usuarios',
    'dashboard.users.subtitle': 'Tu equipo',
    'dashboard.users.button': 'Agregar usuario',
    'dashboard.users.table-name': 'Nombre',
    'dashboard.users.table-email': 'Email',
    'dashboard.users.table-role': 'Rol',
    'dashboard.users.table-actions': 'Opciones',
    'dashboard.users.table-actions-assign-role': 'Asignar rol',
    'dashboard.users.table-actions-resend-credentials': 'Reenviar credenciales',
    'dashboard.users.table-actions-delete': 'Eliminar',
    'dashboard.users.table-no-users': 'No se encontraron usuarios',
    'dashboard.users.assign-role-title': 'Asignar rol a {{name}}',
    'dashboard.users.assign-role-confirm': 'Confirmar',
    'dashboard.users.assign-role-close': 'Cerrar',
    'dashboard.users.assign-role-success': 'Rol asignado con exito',
    'dashboard.users.assign-role-error': 'Error al asignar rol',
    'dashboard.users.invite-users-title': 'Invitar Usuarios',
    'dashboard.users.invite-users.description':
      'Selecciona a los usuarios de tu espacio de trabajo de Slack que deseas invitar',
    'dashboard.users.invite-users.not-found':
      'No se encontraron usuarios del espacio de trabajo',
    'dashboard.users.invite-users.integration-required': `⚠ Para invitar usuarios necesitas instalar y permitir permisos de la aplicación Together en tu espacio de trabajo de Slack.<br /> ✋ Recuerda que debes ser un <b> administrador del espacio de trabajo</b> en el Slack de tu empresa para proceder con la instalación. <br /> 🚧 La aplicación Together está en proceso de ser aprobada por el equipo de Slack.`,
    'dashboard.users.invite-users.get-success':
      '¡Usuarios de Slack obtenidos con éxito!',
    'dashboard.users.invite-users.get-error': 'Error al conectar con Slack',
    'dashboard.users.invite-users.invite-success':
      '¡Usuario invitado con éxito!',
    'dashboard.users.invite-users.invite-error': 'Error al invitar al usuario',
    'dashboard.users.invite-users.email-invitation-description':
      'Puedes invitar mediante email',
    'dashboard.users.invite-users.email-invitation-btn': 'Invitar',
    'dashboard.users.invite-users.table-name': 'Nombre completo',
    'dashboard.users.invite-users.table-email': 'Email',
    'dashboard.users.invite-users.table-options': 'Opciones',
    'dashboard.users.invite-users.name-placeholder': 'Nombre',
    'dashboard.users.invite-users.last-name-placeholder': 'Apellido',
    'dashboard.users.invite-users.email-placeholder': 'Email',
    'dashboard.users.invite-users.table-no-users':
      'No hay ningun usuario para invitar',
    'dashboard.users.resend-credentials-success':
      'Credenciales reenviadas con éxito',
    'dashboard.users.resend-credentials-error': 'Error al enviar credenciales',
    'dashboard.users.resend-credentials-modal-text': 'Reenviar credenciales a ',
    'dashboard.users.delete-user-success': 'Usuario eliminado con éxito',
    'dashboard.users.delete-user-error': 'Error al eliminar usuario',
    'dashboard.users.delete-user-modal-title': 'Confirmar',
    'dashboard.users.delete-user-modal-text': 'Estas por eliminar al usuario ',

    // Dashboard - Teams
    'dashboard.teams.title': 'Equipos',
    'dashboard.teams.table-name': 'Nombre',
    'dashboard.teams.add-team-button': 'Agregar equipo',
    'dashboard.teams.table-host': 'Host',
    'dashboard.teams.table-members': 'Miembros',
    'dashboard.teams.table-actions': 'Acciones',
    'dashboard.teams.table-actions-manage': 'Administrar',
    'dashboard.teams.table-actions-delete': 'Eliminar',
    'dashboard.teams.create-team-modal.title': 'Crear equipo',
    'dashboard.teams.create-team-modal.name-label': 'Nombre',
    'dashboard.teams.create-team-modal.name-placeholder': 'Nombre del equipo',
    'dashboard.teams.create-team-modal.language-label': 'Lenguaje',
    'dashboard.teams.create-team-modal.name-error': 'Nombre requerido',
    'dashboard.teams.create-team-modal.language-error': 'Lenguaje requerido',
    'dashboard.teams.create-team-modal.language-english-option': 'Inglés',
    'dashboard.teams.create-team-modal.language-spanish-option': 'Español',
    'dashboard.teams.create-team-modal.success': 'Equipo creado con éxito!',
    'dashboard.teams.create-team-modal.error': 'Error al crear equipo',
    'dashboard.teams.delete-team-modal-title': 'Eliminar equipo',
    'dashboard.teams.delete-team-modal-text':
      '¿Seguro que quieres eliminar el equipo ',
    'dashboard.teams.create-team-modal.submit-button': 'Confirmar',
    'dashboard.teams.create-team-modal.cancel-button': 'Cancelar',
    'dashboard.teams.view.team-title': 'Equipo',
    'dashboard.teams.view.team-sessions-title': 'Sesiones del equipo',
    'dashboard.teams.view.add-member-button': 'Agregar miembro',
    'dashboard.teams.view.edit-team-button': 'Editar',
    'dashboard.teams.view.language-label': 'Lenguaje',
    'dashboard.teams.view.edit-team-modal-title': 'Editar equipo',
    'dashboard.teams.view.edit-team-modal.success':
      'Equipo actualizado con éxito!',
    'dashboard.teams.view.edit-team-modal.error': 'Error al actualizar equipo',
    'dashboard.teams.view.add-member-modal-title':
      'Seleccionar nuevos miembros del equipo',
    'dashboard.teams.view.add-member-modal-cancel-button': 'Cancelar',
    'dashboard.teams.view.add-member-modal-add-button': 'Agregar',
    'dashboard.teams.view.add-member-modal-error': 'Error al agregar miembro',
    'dashboard.teams.view.add-member-modal-success':
      'Miembro/s agregado/s con éxito!',
    'dashboard.teams.view.table-action-remove': 'Eliminar',
    'dashboard.teams.view.member.table-no-members':
      'No hay miembros en el equipo',
    'dashboard.teams.view.remove-member-modal-text':
      '¿Seguro que quieres quitar a ',
    'dashboard.teams.view.remove-member-modal-title': 'Eliminar miembro',
    'dashboard.teams.view.remove-member-modal-success':
      'Miembro eliminado con éxito!',
    'dashboard.teams.view.remove-member-modal-error':
      'Error al eliminar miembro',
    'dashboard.teams.view.change-host-modal-success':
      'Host cambiado con éxito!',
    'dashboard.teams.view.change-host-modal-error': 'Error al cambiar el host',
    'dashboard.teams.view.change-host-modal-title': 'Seleccionar nuevo host',
    'dashboard.teams.view.change-host-modal-cancel-button': 'Cancelar',
    'dashboard.teams.view.change-host-modal-change-button': 'Confirmar',

    // Dashboard - Experiences
    'dashboard.experiences.title': 'Experiencias',
    'dashboard.experiences.fetch-error': 'Algo salió mal: {{error}}',
    'dashboard.experiences.details.min-participants': 'Mínimo 2 participantes',
    'dashboard.experiences.details.res-download':
      'Descargar recurso para esta experiencia',
    'dashboard.experiences.details.instructions-title':
      'Instrucciones para el equipo',
    'dashboard.experiences.start-session-title': '¿A quién quieres invitar?',
    'dashboard.experiences.start-session-subtitle':
      'Selecciona los usuarios que quieres invitar',
    'dashboard.experiences.start-session-no-users':
      'No se encontraron usuarios. ¡Intenta invitarlos desde tu espacio de trabajo de Slack!',
    'dashboard.experiences.start-session-fetch-users-error':
      'Error al intentar obtener usuarios.',
    'dashboard.experiences.start-session-success':
      '¡Sesión creada exitosamente!',
    'dashboard.experiences.start-session-error': 'Error al crear la sesión',
    'dashboard.experiences.start-session-name-input-label':
      'Nombre de la sesión',
    'dashboard.experiences.start-session-name-input-placeholder':
      'Nombre de la sesión',
    'dashboard.experiences.start-session-name-input-error':
      'El nombre de la sesión solo puede contener letras y números',
    'dashboard.experiences.start-session-date-input-label': 'Fecha',
    'dashboard.experiences.start-session-confirm-button': 'Confirmar',
    'dashboard.experiences.start-session-section-title': 'Iniciar sesión',
    'dashboard.experiences.start-session-section-subtitle':
      'Puedes programar esta experiencia agregando a tus miembros del equipo o invitando a nuevos miembros',
    'dashboard.experiences.start-session-section-button': 'Iniciar experiencia',
    'dashboard.experiences.start-session-select-team-label':
      'Seleccionar equipo',
    'dashboard.experiences.start-session-select-team-option-label':
      'Sin equipo',
    'dashboard.experiences.feedback-title': 'Reseñas',
    'dashboard.experiences.no-feedback': 'No hay reseñas',
    'dashboard.experiences.language-label': 'Lenguaje de las experiencias: ',
    'dashboard.experiences.category': 'Categoría:',
    'dashboard.experiences.no-experiences': 'No hay experiencias disponibles',

    // Dashboard - Settings
    'dashboard.settings.title': 'Ajustes',
    'dashboard.settings.tabs.general': 'General',
    'dashboard.settings.general.title': 'General',
    'dashboard.settings.general.description':
      'Configuraciones generales de la aplicación',
    'dashboard.settings.tabs.billing': 'Facturación',
    'dashboard.settings.billing.title': 'Facturación',
    'dashboard.settings.billing.description':
      'Selecciona tu plan de facturación',
    'dashboard.settings.billing.free-plan-card-title': 'Gratis',
    'dashboard.settings.billing.free-plan-card-price-label': '/ mes',
    'dashboard.settings.billing.free-plan-card-description-1':
      'Experiencias limitadas',
    'dashboard.settings.billing.free-plan-card-btn-current': 'Plan actual',
    'dashboard.settings.billing.free-plan-card-btn-update': 'Activar gratis',
    'dashboard.settings.billing.pro-plan-card-title': 'Pro',
    'dashboard.settings.billing.pro-plan-card-price-label': '/ mes',
    'dashboard.settings.billing.pro-plan-card-description-1':
      'Experiencias ilimitadas',
    'dashboard.settings.billing.pro-plan-card-btn-current': 'Plan actual',
    'dashboard.settings.billing.pro-plan-card-btn-update': 'Activar Pro',

    // Experience Card
    'experience.play-button': 'Iniciar',
    'experience.view-board-button': 'Ver tablero',
    'experience.min-participants': 'Mínimo 2 participantes',

    // Dashboard - Sessions
    'dashboard.sessions.title': 'Sesiones',
    'dashboard.sessions.no-sessions': 'No hay sesiones aún',
    'dashboard.sessions.finish-success': 'Sesión finalizada con éxito',
    'dashboard.sessions.finish-error': 'Error al finalizar la sesión',
    'dashboard.sessions.finish-title': 'Finalizar sesión',
    'dashboard.sessions.feedback-copy-success': 'Link de feedback copiado',
    'dashboard.sessions.table-name': 'Nombre',
    'dashboard.sessions.table-host': 'Anfitrión',
    'dashboard.sessions.table-experience': 'Experiencia',
    'dashboard.sessions.table-participants': 'Participantes',
    'dashboard.sessions.table-date': 'Fecha',
    'dashboard.sessions.table-status': 'Estado',
    'dashboard.sessions.table-actions': 'Acciones',
    'dashboard.sessions.table-action-copy-feedback': 'Copiar link de reseña',
    'dashboard.sessions.table-action-send-feedback': 'Enviar reseña',
    'dashboard.sessions.table-action-board': 'Tablero',
    'dashboard.sessions.table-action-finish': 'Finalizar',
    'dashboard.sessions.card-rating': 'Calificación',
    'dashboard.sessions.card-comment': 'Comentario',
    'dashboard.sessions.card-feedback-pending': 'Reseña pendiente',
    'dashboard.sessions.feedback-form-title':
      'Deja tu reseña para la última sesión',
    'dashboard.sessions.feedback-form-rating': 'Calificación:',
    'dashboard.sessions.feedback-form-comment-label': 'Comentario',
    'dashboard.sessions.feedback-form-comment-placeholder': 'Opcional',
    'dashboard.sessions.feedback-form-submit': 'Enviar',
    'dashboard.sessions.feedback-form-success': 'Reseña enviada',
    'dashboard.sessions.feedback-form-error': 'Error al enviar reseña',

    // Forgot Password
    'forgot-password.title': 'Recuperar contraseña',
    'forgot-password.email-label': 'Email',
    'forgot-password.new-password-label': 'Nueva contraseña',
    'forgot-password.repeat-password-label': 'Repetir contraseña',
    'forgot-password.code-label': 'Codigo',
    'forgot-password.btn-reset-password': 'Recuperar contraseña',
    'forgot-password.btn-send-code': 'Enviar codigo',
    'forgot-password.reset-password-success': 'Contraseña cambiada',
    'forgot-password.reset-password-error':
      'Error al cambiar la contraseña, por favor inténtalo de nuevo',
    'forgot-password.send-code-success': 'Codigo enviado',
    'forgot-password.send-code-error': 'Error al enviar el código',

    // Misc
    'paginator.next': 'Siguiente',
    'paginator.previous': 'Anterior',
    'search.by-name': 'Nombre',
    'search.by-email': 'Email',
    'search.placeholder': 'Buscar',
    'confirmation.modal.title': 'Confirmar',
    'confirmation.modal.text': '¿Estas seguro?',
    'confirmation.modal.error': 'Error al confirmar',
    'confirmation.modal.confirm': 'Confirmar',
    'confirmation.modal.close': 'Cerrar',
    'locale.select': 'Lenguaje:',
    'session-status-finished': 'Finalizada',
    'session-status-pending': 'Pendiente',
  },
};

export default translations;
