import type { AxiosResponse } from 'axios';
import { post } from '@/lib/services/axios/api-axios';

type SubmissionPayload = {
  orgName: string;
  name: string;
  lastName: string;
  email: string;
  trialSessionScheduledDate?: string;
  trialSessionParticipantsMails?: string;
};

export const sendSubmission = async (
  submissionPayload: SubmissionPayload,
): Promise<AxiosResponse> => {
  return post('/onboarding/submit', submissionPayload);
};
