<script lang="ts">
  import { SubscriptionPlan } from '@/interface/IExperience';
  import Page from '@/lib/components/page/Page.svelte';
  import {
    createBillingPortal,
    upgradePlan,
  } from '@/lib/services/together/together.api';
  import { t } from '@/locales/i18n';
  import { userStore } from '@/store/userStore';

  const activeSubscription = $userStore?.tenant?.subscriptionPlan;

  const handleUpgradeToPro = async () => {
    try {
      const { checkoutUrl } = await upgradePlan();
      window.location.href = checkoutUrl;
    } catch (err) {
      console.log(err);
    }
  };

  const handleSetToFree = async () => {
    try {
      const { billingPortalUrl } = await createBillingPortal();
      window.open(billingPortalUrl, '_blank');
    } catch (err) {
      console.log(err);
    }
  };
</script>

<Page>
  <div
    class="h-max-fit flex w-full flex-col gap-4 rounded-none border-x border-t border-light-grey bg-white p-8"
  >
    <h1 class="text-3xl font-bold">{$t('dashboard.settings.billing.title')}</h1>
    <p>{$t('dashboard.settings.billing.description')}</p>
    <div
      class="mb-12 flex flex-col items-center justify-center gap-12 p-8 md:flex-row"
    >
      <div
        class="flex flex-col gap-3 rounded-lg border-2 border-light-grey bg-white p-8"
      >
        <h2 class="text-center text-3xl font-bold">
          {$t('dashboard.settings.billing.free-plan-card-title')}
        </h2>
        <h3 class="mt-4 text-center text-5xl font-bold">
          $0 <span class="text-sm"
            >{$t('dashboard.settings.billing.free-plan-card-price-label')}</span
          >
        </h3>
        <div class="mt-12 border-t border-gray-700" />
        <ul class="mt-4 list-disc ps-4">
          <li>
            {$t('dashboard.settings.billing.free-plan-card-description-1')}
          </li>
        </ul>
        <div class="mt-4 border-t border-gray-700" />
        <div class="mt-8 flex justify-center">
          <button
            data-cy="set-to-free-button"
            disabled={activeSubscription === SubscriptionPlan.FREE}
            class="relative rounded bg-primary px-4 py-2 text-white transition duration-200 ease-in ${activeSubscription ===
            SubscriptionPlan.FREE
              ? 'cursor-not-allowed opacity-50'
              : 'hover:bg-primary/50'}"
            on:click={handleSetToFree}
          >
            {#if activeSubscription === SubscriptionPlan.FREE}
              {$t('dashboard.settings.billing.free-plan-card-btn-current')}
            {:else}
              {$t('dashboard.settings.billing.free-plan-card-btn-update')}
            {/if}
          </button>
        </div>
      </div>
      <div
        class="flex flex-col gap-3 rounded-lg border-2 border-primary bg-primary p-8 text-white"
      >
        <h2 class="text-center text-3xl font-bold">
          {$t('dashboard.settings.billing.pro-plan-card-title')}
        </h2>
        <h3 class="mt-4 text-center text-5xl font-bold">
          $10 <span class="text-sm"
            >{$t('dashboard.settings.billing.pro-plan-card-price-label')}</span
          >
        </h3>
        <div class="mt-12 border-t border-white/50" />
        <ul class="mt-4 list-disc ps-4">
          <li>
            {$t('dashboard.settings.billing.pro-plan-card-description-1')}
          </li>
        </ul>
        <div class="mt-4 border-t border-white/50" />
        <div class="mt-8 flex justify-center">
          <button
            data-cy="upgrade-to-pro-button"
            disabled={activeSubscription === SubscriptionPlan.PRO}
            class="relative rounded bg-white px-4 py-2 text-primary transition duration-200 ease-in hover:bg-white/50 ${activeSubscription ===
            SubscriptionPlan.PRO
              ? 'cursor-not-allowed opacity-50'
              : ''}"
            on:click={handleUpgradeToPro}
          >
            {#if activeSubscription === SubscriptionPlan.FREE}
              {$t('dashboard.settings.billing.pro-plan-card-btn-update')}
            {:else}
              {$t('dashboard.settings.billing.pro-plan-card-btn-current')}
            {/if}
          </button>
        </div>
      </div>
    </div>
  </div>
</Page>
