<script lang="ts">
  import type { IUser } from '@/interface/IUser';

  export let users: IUser[];
  export let selectedUserIds: Set<IUser['id']>;
  export let onSelect: (user: IUser) => void;
  export let disableCondition: ((user: IUser) => boolean) | undefined =
    undefined;

  let userList: IUser[] = users;
  let selectedUserIdsList: Set<IUser['id']> = selectedUserIds;

  $: {
    userList = users;
    selectedUserIdsList = selectedUserIds;
  }
</script>

<ul
  class=" flex h-64 w-full flex-col gap-2 overflow-auto rounded-lg border border-light-grey px-4 py-2"
>
  {#each userList as user}
    <li class="flex w-full flex-row items-center">
      <label class="flex w-[30rem] items-center gap-2">
        <input
          data-cy="invite-user-checkbox-{user.id}"
          class="accent-primary"
          type="checkbox"
          disabled={disableCondition?.(user)}
          checked={selectedUserIdsList.has(user.id)}
          on:change={() => onSelect?.(user)}
        />
        <span class="font-semibold">{user.name} {user.lastName}</span>
      </label>
      <div class="flex w-full flex-row items-center justify-between gap-4">
        <span>{user.email}</span>
        <span>{user.role}</span>
      </div>
    </li>
  {/each}
</ul>
