<script lang="ts">
  import { onMount } from 'svelte';
  import { UserRole, type ITenant } from '@/interface/IUser';
  import { getAllTenants } from '@/lib/services/together/tenant.api';
  import { changeTenant } from '@/lib/services/together/user.api';
  import { t } from '@/locales/i18n';
  import { userStore } from '@/store/userStore';

  let tenants: ITenant[] = [];

  let selectedTenantId: number = $userStore?.tenant.id || 0;
  const handleChangeTenant = async (): Promise<void> => {
    await changeTenant(selectedTenantId);
    window.location.reload();
  };

  onMount(async () => {
    if ($userStore?.role === UserRole.SUPER_ADMIN) {
      tenants = await getAllTenants();
    }
  });
</script>

<div class="mb-4 ms-10 flex w-full flex-col items-start justify-center gap-2">
  <span class="font-bold text-dark-grey" data-cy="tenant-selector-title"
    >{$t('dashboard.sidebar.tenant-selector')}</span
  >
  <select
    class="px-1 font-normal hover:cursor-pointer"
    bind:value={selectedTenantId}
    on:change={handleChangeTenant}
    data-cy="tenant-selector"
  >
    {#each tenants as tenant}
      <option value={tenant.id}>{tenant.name}</option>
    {/each}
  </select>
</div>
