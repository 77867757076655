<script lang="ts">
  import { onMount } from 'svelte';
  import { navigate, Route, Router } from 'svelte-navigator';
  import { UserRole } from '@/interface/IUser';
  import { t } from '@/locales/i18n';
  import { userStore } from '@/store/userStore';

  import BillingSettings from './billing/BillingSettings.svelte';
  import GeneralSettings from './general/GeneralSettings.svelte';

  let isAdmin: boolean =
    $userStore?.role === UserRole.ADMIN ||
    $userStore?.role === UserRole.SUPER_ADMIN;

  let activeTab = !isAdmin ? 'general' : 'billing';

  function switchTab(tab: string) {
    activeTab = tab;
    navigate(`/dashboard/settings/${tab}`);
  }

  onMount(() => {
    switchTab(activeTab);
  });
</script>

<Router basepath="/dashboard/settings/*">
  <Route path="/*">
    <div
      class="flex h-16 w-full flex-row items-center justify-start border-b border-light-grey px-6"
    >
      <h1 class="mt-1 text-xl font-bold text-primary">
        {$t('dashboard.settings.title')}
      </h1>
    </div>

    <div class="my-2 ms-2 flex flex-wrap items-center gap-2">
      {#if isAdmin}
        <button
          data-cy="billing-section-button"
          class={`rounded-t-lg px-4 py-2 text-gray-600 hover:bg-primary hover:text-white focus:bg-primary focus:opacity-90 focus:outline-none ${
            activeTab === 'billing' ? 'bg-primary text-white opacity-90' : ''
          }`}
          on:click={() => switchTab('billing')}
        >
          {$t('dashboard.settings.tabs.billing')}
        </button>
      {/if}
      <button
        data-cy="general-section-button"
        class={`rounded-t-lg px-4 py-2 text-gray-600 hover:bg-primary hover:text-white focus:bg-primary focus:opacity-90 focus:outline-none ${
          activeTab === 'general' ? 'bg-primary text-white opacity-90' : ''
        }`}
        on:click={() => switchTab('general')}
      >
        {$t('dashboard.settings.tabs.general')}
      </button>
    </div>

    <div class="border-t border-gray-200">
      {#if activeTab === 'billing'}
        <Route path="/billing">
          <BillingSettings />
        </Route>
      {:else if activeTab === 'general'}
        <Route path="/general">
          <GeneralSettings />
        </Route>
      {/if}
    </div>
  </Route>
</Router>
