<script lang="ts">
  import type { ISession } from '@/interface/ISession';
  import Loading from '@/lib/components/loading/Loading.svelte';
  import Paginator from '@/lib/components/paginator/Paginator.svelte';
  import SessionTable from '@/lib/components/tables/SessionTable.svelte';
  import {
    getSessions,
    type ISessionQuery,
  } from '@/lib/services/together/together.api';

  export let teamId: number;

  let loading = false;
  let sessions: ISession[] | null = null;
  let count = 0;

  let sessionQuery: ISessionQuery = {
    limit: 10,
    offset: 0,
    teamId: teamId,
  };

  const fetchSessions = async () => {
    loading = true;
    const result = await getSessions(sessionQuery);
    sessions = result.sessions;
    count = result.count;
    loading = false;
  };

  $: sessionQuery, fetchSessions();
</script>

<div class="flex grow flex-col gap-4 rounded-md border border-light-grey p-6">
  {#if loading}
    <div class="flex h-full w-full grow flex-col items-center justify-center">
      <Loading loadingSize="h-32 w-32" />
    </div>
  {:else if sessions}
    <SessionTable sessions={sessions} />
    <Paginator
      total={count}
      query={sessionQuery}
      onChange={(newQuery) => (sessionQuery = { ...newQuery })}
    />
  {/if}
</div>
