<script lang="ts">
  import { validator } from '@felte/validator-yup';
  import { createForm } from 'felte';
  import * as yup from 'yup';
  import Button from '@/lib/components/button/Button.svelte';
  import Input from '@/lib/components/input/Input.svelte';
  import Loading from '@/lib/components/loading/Loading.svelte';
  import { ToastNotification } from '@/lib/services/toast';
  import { sendSubmission } from '@/lib/services/together/onboarding.api';
  import { t } from '@/locales/i18n';

  export let nextAction: () => void;
  let applyTrial = false;

  const schema = yup.object({
    'org-name': yup.string().required($t('signup.form-orgname-required')),
    name: yup.string().required($t('signup.form-name-required')),
    'last-name': yup.string().required($t('signup.form-lastname-required')),
    email: yup
      .string()
      .email($t('signup.form-email-invalid'))
      .required($t('signup.form-email-required')),
    'accept-terms': yup
      .boolean()
      .oneOf([true], $t('signup.form-terms-required')),
    'trial-session-schedule-date': yup.string(),
    'trial-session-participants-mails': yup
      .string()
      .matches(
        /^(?!.*((?:(?<![\w.\-+%])[\w._%+-]+@[\w.-]+.[a-zA-Z]{2,}\b)).*\b\1\b)(?:[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})(?:,(?:[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}))*$/,
        $t('signup.form-trial-session-participants-mails-invalid'),
      ),
  });

  type FormData = yup.InferType<typeof schema>;

  type Field = {
    name: keyof FormData;
    label: string;
    type: string;
    placeholder: string;
  };

  const { form, errors, isSubmitting } = createForm<
    yup.InferType<typeof schema>
  >({
    initialValues: {
      'org-name': '',
      name: '',
      'last-name': '',
      email: '',
      'accept-terms': false,
    },
    extend: [validator({ schema })],
    onSubmit: async (values) => {
      try {
        await sendSubmission({
          ...values,
          orgName: values['org-name'],
          lastName: values['last-name'],
          trialSessionScheduledDate: values['trial-session-schedule-date'],
          trialSessionParticipantsMails:
            values['trial-session-participants-mails'],
        });

        nextAction();
        ToastNotification.success($t('signup.form-success'));
      } catch (error: any) {
        const errorMessage = error?.response?.data?.message;
        if (errorMessage) {
          ToastNotification.error(errorMessage);
        } else {
          ToastNotification.error($t('signup.form-error'));
        }
      }
    },
  });

  const fields: Field[] = [
    {
      name: 'org-name',
      label: $t('signup.form-orgname-input-label'),
      type: 'text',
      placeholder: $t('signup.form-orgname-input-placeholder'),
    },
    {
      name: 'name',
      label: $t('signup.form-name-input-label'),
      type: 'text',
      placeholder: $t('signup.form-name-input-placeholder'),
    },
    {
      name: 'last-name',
      label: $t('signup.form-lastname-input-label'),
      type: 'text',
      placeholder: $t('signup.form-lastname-input-placeholder'),
    },
    {
      name: 'email',
      label: $t('signup.form-email-input-label'),
      type: 'email',
      placeholder: $t('signup.form-email-input-placeholder'),
    },
  ];
</script>

<form
  use:form
  class="z-20 flex w-[27rem] flex-col items-center justify-center gap-6 rounded-lg px-12 pb-4 pt-1 xl:w-[35rem]"
>
  <h2
    class="text-center text-5xl font-bold text-primary"
    data-cy="submission-form-title"
  >
    {@html $t('signup.form-title')}
  </h2>
  <p
    class="text-center text-xl text-dark-grey"
    data-cy="submission-form-subtitle"
  >
    {@html $t('signup.form-subtitle')}
  </p>
  <div class="flex w-full flex-col gap-4" data-cy="submission-form">
    {#each fields as field}
      <Input
        name={field.name}
        type={field.type}
        placeholder={field.placeholder}
        testId={`${field.name}-input`}
        error={$errors[field.name] ? $errors[field.name]?.[0] : ''}
        styleClass="mt-1"
      />
    {/each}
    <Input
      type="checkbox"
      name="accept-terms"
      error={$errors['accept-terms'] ? $errors['accept-terms']?.[0] : ''}
      testId="accept-terms"
    >
      <span>
        {@html $t('signup.form-accept-terms')}
      </span>
    </Input>
    <Input
      type="checkbox"
      name="apply-trial"
      on:change={() => (applyTrial = !applyTrial)}
    >
      <span> I want to get a free trial session! </span>
    </Input>
    {#if applyTrial}
      <div class="flex flex-col gap-2">
        <p class="text-xs text-grey">
          Enter the emails of the people you want to invite to the trial session
          and the date you want to schedule it
        </p>
        <Input
          type="text"
          placeholder="Emails separated by commas"
          name="trial-session-participants-mails"
          error={$errors['trial-session-participants-mails']
            ? $errors['trial-session-participants-mails']?.[0]
            : ''}
          testId="trial-session-participants-mails"
        />
        <Input
          type="date"
          placeholder="Date"
          name="trial-session-schedule-date"
          error={$errors['trial-session-schedule-date']
            ? $errors['trial-session-schedule-date']?.[0]
            : ''}
          testId="trial-session-schedule-date"
        />
      </div>
    {/if}

    <Button
      testId="signup-button"
      disabled={$isSubmitting}
      submit
      rounded="rounded-md"
    >
      {#if $isSubmitting}
        <Loading loadingSize="h-6 w-6" />
      {:else}
        <span class="text-xl">{@html $t('signup.form-confirm')}</span>
      {/if}
    </Button>
  </div>
</form>
