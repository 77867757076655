import { get as getStore } from 'svelte/store';
import { type ICategory } from '@/interface/ICategory';
import type { ExperienceLanguages, IExperience } from '@/interface/IExperience';
import type { IPaginatedQuery } from '@/interface/IPaginatedQuery';
import type { ISession } from '@/interface/ISession';
import type { ISessionUser } from '@/interface/ISessionUser';
import { type ITeam } from '@/interface/ITeam';
import type { IUser } from '@/interface/IUser';
import type { IUserQuery } from '@/interface/IUserQuery';
import { userStore } from '@/store/userStore';

import { del, get, post, put } from '../axios/api-axios';

export const getTenantUsers = async (query?: IUserQuery) => {
  const user = getStore(userStore);
  return await get<{ users: IUser[]; count: number }>('/user', {
    params: { ...query, tenantId: user!.tenant.id },
  });
};

export const updateUser = async (
  userId: number,
  updatedUser: Partial<IUser>,
) => {
  return await put(`/user/${userId}`, updatedUser);
};
export interface IExperienceQuery extends IPaginatedQuery {
  categoryId?: number;
  language?: ExperienceLanguages;
}
export const getExperiences = async (query?: IExperienceQuery) => {
  return await get<{ experiences: IExperience[]; count: number }>(
    '/experiences',
    {
      params: query,
    },
  );
};

interface IExperienceFeedbackQuery extends IPaginatedQuery {
  minRating?: number;
}
export const getExperienceFeedback = async (
  experienceId: number,
  query?: IExperienceFeedbackQuery,
) => {
  return await get<{ count: number; sessionUsers: ISessionUser[] }>(
    `/experiences/${experienceId}/feedback`,
    {
      params: query,
    },
  );
};

export const getExperienceById = async (experienceId: number) => {
  return await get<IExperience>(`/experiences/${experienceId}`);
};

export const createSession = async (
  experienceId: number,
  hostId: number,
  userIds: number[],
  name: string,
  scheduledDate: string,
  teamId?: number,
) => {
  return await post<ISession>(`/sessions`, {
    experienceId,
    hostId,
    userIds,
    name: name.trim(),
    scheduledDate: new Date(scheduledDate + 'T00:00:00').toISOString(),
    teamId,
  });
};

export interface ISessionQuery extends IPaginatedQuery {
  teamId?: number;
}

export const getSessions = async (query?: ISessionQuery) => {
  return await get<{ sessions: ISession[]; count: number }>('/sessions', {
    params: query,
  });
};

export const updateSession = async (
  sessionId: number,
  updatedSession: Partial<ISession>,
) => {
  return await put(`/sessions/${sessionId}`, updatedSession);
};

export const finishSession = async (sessionId: number) => {
  return await put(`/sessions/${sessionId}/finish`);
};

export const submitFeedback = async (
  sessionId: number,
  feedback: { rating: number; comment: string },
) => {
  return await put(`/sessions/${sessionId}/feedback`, feedback);
};

export const getLandingExperienceCards = async (language: string) => {
  return await get<{ experiences: IExperience[]; count: number }>(
    '/public/experiences',
    {
      params: {
        language: language,
      },
    },
  );
};

export const getAllCategories = async () => {
  return await get<{ categories: ICategory[]; count: number }>('/categories');
};

export const upgradePlan = async (): Promise<{ checkoutUrl: string }> => {
  return await post('/payment/upgrade-plan');
};

export const createBillingPortal = async (): Promise<{
  billingPortalUrl: string;
}> => {
  return await get('/payment/billing-portal');
};

export interface ITeamsQuery extends IPaginatedQuery {
  name?: string;
  language?: ExperienceLanguages;
}
export const getTeams = async (
  query?: ITeamsQuery,
): Promise<{ teams: ITeam[]; count: number }> => {
  return await get<{ teams: ITeam[]; count: number }>('/teams', {
    params: query,
  });
};

export const getTeamById = async (teamId: number): Promise<ITeam> => {
  return await get<ITeam>('/teams/' + teamId);
};

type ITeamPayload = Omit<
  ITeam,
  'id' | 'users' | 'host' | 'createdAt' | 'updatedAt' | 'deletedAt'
>;
export const createTeam = async (payload: ITeamPayload) => {
  return await post<ITeam>('/teams', payload);
};

export const updateTeam = async (payload: ITeamPayload, teamId: number) => {
  return await put<ITeam>('/teams/' + teamId, payload);
};

export const deleteTeam = async (teamId: number) => {
  return await del('/teams/' + teamId);
};

export const addTeamMember = async (teamId: number, userId: number) => {
  return await post('/teams/' + teamId + '/users', { userId });
};

export const removeTeamMember = async (teamId: number, userId: number) => {
  return await del('/teams/' + teamId + '/users/' + userId);
};

export const changeTeamHost = async (teamId: number, hostId: number) => {
  return await put('/teams/' + teamId + '/host', { hostId });
};

export const editTeam = async (teamId: number, payload: ITeamPayload) => {
  return await put('/teams/' + teamId, payload);
};
