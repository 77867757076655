<script lang="ts">
  import { navigate } from 'svelte-navigator';
  import Button from '@/lib/components/button/Button.svelte';
  import Input from '@/lib/components/input/Input.svelte';
  import Loading from '@/lib/components/loading/Loading.svelte';
  import { confirmNewPassword, passwordRecovery } from '@/lib/services/cognito';
  import { ToastNotification } from '@/lib/services/toast';
  import { t } from '@/locales/i18n';

  import { confirmPasswordSchema } from './confirmPasswordValidation.schema';

  let email = '';
  let code = '';
  let newPassword = '';
  let repeatPassword = '';
  let errors: {
    newPassword: string | undefined;
    repeatPassword: string | undefined;
  } = {
    newPassword: undefined,
    repeatPassword: undefined,
  };
  let codeSent = false;
  let loading = false;

  const validateFields = () => {
    const validationErrors = confirmPasswordSchema.validate(
      { newPassword, repeatPassword },
      { abortEarly: false },
    );

    errors = {
      newPassword: validationErrors.error?.details.find(
        (error) => error.context?.key === 'newPassword',
      )?.message,
      repeatPassword: validationErrors.error?.details.find(
        (error) => error.context?.key === 'repeatPassword',
      )?.message,
    };
    if (errors.newPassword || errors.repeatPassword) return false;
    return true;
  };

  const handleCodeSent = async () => {
    try {
      loading = true;
      await passwordRecovery(email);
      codeSent = true;
      ToastNotification.success($t('forgot-password.send-code-success'));
    } catch (error) {
      ToastNotification.error($t('forgot-password.send-code-error'));
    } finally {
      loading = false;
    }
  };
  const handleNewPassword = async () => {
    if (!validateFields()) return;

    try {
      loading = true;
      confirmNewPassword(newPassword, code, email);
      ToastNotification.success($t('forgot-password.reset-password-success'));
      navigate('/login', { replace: true });
    } catch (error) {
      ToastNotification.error($t('forgot-password.reset-password-error'));
    } finally {
      loading = false;
    }
  };

  const handleSubmit = async () => {
    if (!codeSent) {
      await handleCodeSent();
    } else {
      await handleNewPassword();
    }
  };
</script>

<div class="flex h-full w-full grow flex-col items-center justify-center">
  <div
    class="flex h-fit w-96 flex-col justify-center gap-6 rounded-lg border border-light-grey bg-white p-6"
  >
    <h1 data-cy="login-modal-title" class="text-3xl text-primary">
      {$t('forgot-password.title')}
    </h1>
    <Input
      bind:value={email}
      label={$t('forgot-password.email-label')}
      name="email"
      type="email"
      testId="email-input"
    />
    {#if codeSent}
      <Input
        bind:value={newPassword}
        label={$t('forgot-password.new-password-label')}
        name="new-password"
        type="password"
        on:input={validateFields}
        on:change={validateFields}
        error={errors.newPassword}
      />
      <Input
        bind:value={repeatPassword}
        label={$t('forgot-password.repeat-password-label')}
        name="repeat-password"
        type="password"
        on:input={validateFields}
        on:change={validateFields}
        error={errors.repeatPassword}
      />
      <Input
        bind:value={code}
        label={$t('forgot-password.code-label')}
        name="code"
        type="number"
      />
    {/if}
    {#if loading}
      <Loading loadingSize="h-6 w-6" />
    {:else}
      <Button
        testId="password-recovery-submit"
        style="fill"
        on:click={handleSubmit}
        ><span
          >{codeSent
            ? $t('forgot-password.btn-reset-password')
            : $t('forgot-password.btn-send-code')}</span
        ></Button
      >
    {/if}
  </div>
</div>
