<script lang="ts">
  import { validator } from '@felte/validator-yup';
  import { createForm } from 'felte';
  import * as yup from 'yup';
  import { ExperienceLanguages } from '@/interface/IExperience';
  import { type ITeam } from '@/interface/ITeam';
  import { ToastNotification } from '@/lib/services/toast';
  import { createTeam, editTeam } from '@/lib/services/together/together.api';
  import { t } from '@/locales/i18n';

  import Button from '../../button/Button.svelte';
  import Input from '../../input/Input.svelte';
  import { closeModal } from '../modal.store';

  export let team: ITeam | undefined = undefined;
  export let onSubmit: (() => void) | undefined = undefined;

  const schema = yup.object({
    name: yup
      .string()
      .required($t('dashboard.teams.create-team-modal.name-error')),
    language: yup
      .string()
      .oneOf([ExperienceLanguages.english, ExperienceLanguages.spanish])
      .required($t('dashboard.teams.create-team-modal.language-error')),
  });

  type FormData = yup.InferType<typeof schema>;

  type Field = {
    name: keyof FormData;
    label: string;
    type: string;
    placeholder?: string;
    options?: { label: string; value: string }[];
  };

  let fields: Field[] = [
    {
      name: 'name',
      label: $t('dashboard.teams.create-team-modal.name-label'),
      type: 'text',
      placeholder: $t('dashboard.teams.create-team-modal.name-placeholder'),
    },
    {
      name: 'language',
      label: $t('dashboard.teams.create-team-modal.language-label'),
      type: 'select',
      options: [
        {
          label: $t(
            'dashboard.teams.create-team-modal.language-english-option',
          ),
          value: ExperienceLanguages.english,
        },
        {
          label: $t(
            'dashboard.teams.create-team-modal.language-spanish-option',
          ),
          value: ExperienceLanguages.spanish,
        },
      ],
    },
  ];

  const initialValues = team
    ? {
        name: team.name,
        language: team.language,
      }
    : {
        name: '',
        language: ExperienceLanguages.english,
      };
  const { form, errors, isSubmitting } = createForm<FormData>({
    initialValues,
    extend: [validator({ schema })],
    onSubmit: async (values) => {
      await handleCreateTeam(values.name.trim(), values.language);
    },
  });

  const handleCreateTeam = async (
    name: string,
    language: ExperienceLanguages,
  ) => {
    const successMessage = team
      ? $t('dashboard.teams.view.edit-team-modal.success')
      : $t('dashboard.teams.create-team-modal.success');
    const errorMessage = team
      ? $t('dashboard.teams.view.edit-team-modal.error')
      : $t('dashboard.teams.create-team-modal.error');

    try {
      if (team) {
        await editTeam(team.id, { name, language });
      } else {
        await createTeam({ name, language });
      }

      ToastNotification.success(successMessage);
      closeModal();
      onSubmit?.();
    } catch (error) {
      console.error('Error handling team creation:', error);
      ToastNotification.error(errorMessage);
    }
  };
</script>

<form
  use:form
  class="z-20 flex w-[27rem] flex-col items-center justify-center gap-6 rounded-lg bg-white p-6 pt-8"
>
  <h1 class="text-2xl font-bold text-primary">
    {team
      ? $t('dashboard.teams.view.edit-team-modal-title')
      : $t('dashboard.teams.create-team-modal.title')}
  </h1>
  <div class="flex w-full flex-col gap-4">
    {#each fields as field}
      <Input
        label={field.label}
        name={field.name}
        type={field.type}
        placeholder={field.placeholder}
        testId={`${field.name}-input`}
        error={$errors[field.name] ? $errors[field.name]?.[0] : ''}
        options={field?.options ? field.options : undefined}
      />
    {/each}
  </div>
  <div class="flex w-full flex-row justify-between gap-4">
    <Button
      testId="cancel-button"
      disabled={$isSubmitting}
      on:click={() => closeModal()}
      rounded="rounded-md"
    >
      {$t('dashboard.teams.create-team-modal.cancel-button')}
    </Button>
    <Button
      testId="submit-button"
      disabled={$isSubmitting}
      submit
      rounded="rounded-md"
    >
      {$t('dashboard.teams.create-team-modal.submit-button')}
    </Button>
  </div>
</form>
