import { toast } from '@zerodevx/svelte-toast';

export class ToastNotification {
  static success(message: string, options?: IOptionsProps) {
    toast.push(message, {
      theme: {
        '--toastColor': 'black',
        '--toastBackground': '#90EE90',
        '--toastBarBackground': '#008000',
      },
      ...options,
    });
  }

  static error(message: string, options?: IOptionsProps) {
    toast.push(message, {
      theme: {
        '--toastColor': '#FF0000',
        '--toastBackground': '#FFC0CB',
        '--toastBarBackground': '#FF0000',
      },
      ...options,
    });
  }

  static warning(message: string, options?: IOptionsProps) {
    toast.push(message, {
      theme: {
        '--toastColor': 'black',
        '--toastBackground': '#FFD700',
        '--toastBarBackground': '#FFA500',
      },
      ...options,
    });
  }

  static normal(message: string, options?: IOptionsProps) {
    toast.push(message, {
      theme: {
        '--toastBackground': 'white',
        '--toastColor': 'black',
        '--toastBarBackground': '#6346FA',
      },
      ...options,
    });
  }

  static removeToasts() {
    toast.pop();
  }
}

interface IOptionsProps {
  pausable?: boolean;
  duration?: number;
  onpop?: () => void;
}
