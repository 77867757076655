<script lang="ts">
  import { validator } from '@felte/validator-yup';
  import { createForm } from 'felte';
  import { navigate } from 'svelte-navigator';
  import * as yup from 'yup';
  import EyeIcon from '@/assets/svg/EyeIcon.svelte';
  import { ToastNotification } from '@/lib/services/toast';
  import { login } from '@/lib/services/together/user.api';
  import { t } from '@/locales/i18n';
  import { loginRedirect } from '@/store/loginRedirectStore';

  import Button from '../button/Button.svelte';
  import Input from '../input/Input.svelte';
  import Loading from '../loading/Loading.svelte';
  import NewPasswordForm from '../new-password-form/NewPasswordForm.svelte';

  let newPasswordRequired = false;
  let currentUserCrendentials = { email: '', password: '' };
  let showPassword = false;

  const schema = yup.object({
    email: yup
      .string()
      .email($t('login.form-email-invalid'))
      .required($t('login.form-email-required')),
    password: yup.string().required($t('login.form-password-required')),
  });

  type FormData = yup.InferType<typeof schema>;

  type Field = {
    name: keyof FormData;
    label: string;
    type: string;
    placeholder: string;
  };

  let fields: Field[] = [
    {
      name: 'email',
      label: $t('login.form-email-input-label'),
      type: 'email',
      placeholder: $t('login.form-email-input-placeholder'),
    },
    {
      name: 'password',
      label: $t('login.form-password-input-label'),
      type: 'password',
      placeholder: $t('login.form-password-input-placeholder'),
    },
  ];

  const { form, errors, isSubmitting } = createForm<FormData>({
    initialValues: {
      email: '',
      password: '',
    },
    extend: [validator({ schema })],
    onSubmit: async ({ email, password }) => {
      try {
        const response = await login(email, password);

        if (response.newPasswordRequired) {
          currentUserCrendentials = { email, password };
          newPasswordRequired = true;
          ToastNotification.success($t('login.form-new-password-required'));
        } else {
          const url = $loginRedirect || '/dashboard';
          $loginRedirect = null;
          navigate(url);
          ToastNotification.success($t('login.form-success'));
        }
      } catch (error: any) {
        const errorMessage = error?.response?.data?.message;
        if (errorMessage) {
          ToastNotification.error(errorMessage);
        } else {
          ToastNotification.error($t('login.form-error'));
        }
      }
    },
  });
  $: fields = fields.map((field) =>
    field.name === 'password'
      ? { ...field, type: showPassword ? 'text' : 'password' }
      : field,
  );
</script>

{#if newPasswordRequired}
  <NewPasswordForm email={currentUserCrendentials.email} />
{:else}
  <form
    use:form
    class="z-20 flex w-[27rem] flex-col items-center justify-center gap-6 rounded-lg p-2 lg:p-20 xl:w-[35rem]"
  >
    <h1 class="text-5xl font-bold text-primary">
      {@html $t('login.form-title')}
    </h1>
    <span class="text-center text-xl text-dark-grey">
      {@html $t('login.form-subtitle')}
    </span>
    <div class="flex w-full flex-col gap-8">
      <div class="flex w-full flex-col gap-4">
        {#each fields as field}
          <Input
            name={field.name}
            type={field.type}
            placeholder={field.placeholder}
            testId={`${field.name}-input`}
            error={$errors[field.name] ? $errors[field.name]?.[0] : ''}
            styleClass="mt-2"
          >
            {#if field.name === 'password'}
              <EyeIcon
                testId="password-toggle-visibility"
                on:click={() => (showPassword = !showPassword)}
                show={showPassword}
                style="w-6 h-6 absolute right-3 text-grey cursor-pointer"
              />
            {/if}
          </Input>
        {/each}
        <div class="self-end">
          <span
            on:keypress
            on:click={() => navigate('/forgot-password')}
            class="cursor-pointer text-right text-primary"
            >{@html $t('login.form-forgot-password')}</span
          >
        </div>
      </div>

      <Button
        testId="login-button"
        disabled={$isSubmitting}
        submit
        rounded="rounded-md"
      >
        {#if $isSubmitting}
          <Loading loadingSize="h-6 w-6" />
        {:else}
          <span class="text-xl">{@html $t('login.form-submit-button')}</span>
        {/if}
      </Button>
      <span class="text-center text-dark-grey"
        >{@html $t('login.form-no-account')}<a
          class="text-primary"
          href="/signup">{@html $t('login.form-signup-link')}</a
        ></span
      >
    </div>
  </form>
{/if}
